<template>
  <div>
    <div v-if="memberName" class="mb-2 text-truncate">
      <v-icon @click="$helper.copyText(memberName)" size="14" left>fa fa-copy</v-icon>

      <v-tooltip left>
        <template #activator="{on}">
          <span v-on="on">
            <router-link :to="memberRoute" target="_blank">
              {{memberName}}
            </router-link>
          </span>
        </template>
        <span>{{memberName}}</span>
      </v-tooltip>
    </div>

    <div v-if="email" class="mb-2 text-truncate">
      <v-icon @click="$helper.copyText(email)" size="14" left>fa fa-copy</v-icon>

      <v-tooltip left>
        <template #activator="{on}">
          <span v-on="on">{{email}}</span>
        </template>
        <span>{{email}}</span>
      </v-tooltip>
    </div>

    <div v-if="phone" class="mb-2 text-truncate">
      <v-icon @click="$helper.copyText(phone)" size="14" left>fa fa-copy</v-icon>
      <v-tooltip left>
        <template #activator="{on}">
          <span v-on="on">{{phone}}</span>
        </template>
        <span>{{phone}}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  computed: {
    memberRoute() {
      return {
        name: 'member-update',
        params: { target: this.member.id },
      }
    },
    member() {
      return this.row.member
    },
    memberName() {
      if(!this.member) return null
      return this.member.name || null
    },
    phone() {
      return this.row.phone
    },
    email() {
      return this.row.email
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
